import React from "react";

import { Table } from "antd";

import "src/components/products/tableTechnicalData/style.scss";

export const IndustrySpecialTableTechnicalData = (props) => {
  const columns = [
    { title: "Propiedad", dataIndex: "property" },
    {
      title: "Valor",
      dataIndex: "value",
    },
    {
      title: "Normativa",
      dataIndex: "norma",
    },
  ];

  const dataSource = [
    ["Densidad", "1,04 kg/dm3", "ISO 2811-1"],
    ["Comportamiento al fuego", "C-s1, d0", "DIN EN 13501-1"],
    [
      "Permeabilidad al vapor de agua",
      "área seca: sd = 0,58 área húmeda:  sd = 0,42",
      "DIN 52615",
    ],
    ["COV", "< 7 g/l", "PN_EN ISO 2811-1:2012"],
    ["Total solar reflectance", "91.4%", "ASTM E903"],
    ["Emisividad", "88.0%", "ASTM C 1371-04a(2010)e1"],
    ["Resistencia a temperatura	", "-40 to + 150°C", "Ensayo Interno"],
    [
      "Resistencia a los Rayos UV",
      "Alta resistencia a los rayos UV",
      "Ensayo Interno",
    ],
    [
      "Adherencia",
      "muy buena adherencia a todas las superficies	",
      "Ensayo Interno",
    ],
    ["Impermeabilidad al agua", "Columna de agua 612 mm", "DIN EN 20811"],
    [
      "Resistencia química",
      "Buena resistencia en una amplia variedad de productos químicos (información disponible bajo consulta)",
      "ASTM 1308-02",
    ],
  ].map((data, i) => ({
    key: i,
    property: data[0],
    value: data[1],
    norma: data[2],
  }));

  return (
    <div className="tableTechnicalData">
      <h2>Propiedades</h2>
      <Table
        {...{
          dataSource,
          columns,
          pagination: false,
          bordered: true,
        }}
      />
      <br />
    </div>
  );
};

export default IndustrySpecialTableTechnicalData;
